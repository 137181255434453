import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import "./styles.scss";
import { Row, Col, Label } from "reactstrap";

import Page from "components/Page";
import PlugOrderTable from "./PlugOrderTable";
import api from "config/api";
import errorRequest from "common/errorRequest";

export default function PlugInfo() {
  const history = useHistory();
  const { idPlug } = useParams();
  const [loading, setLoading] = useState(false);
  const [plug, setPlug] = useState({});

  useEffect(() => {
    fetchPlug();
  }, [])

  async function fetchPlug() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/plugSystem/Plug/${idPlug}`);
      setPlug(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function OrderNumbers() {
    if (plug && plug.VariationPlugs && plug.VariationPlugs.length) {
      const orders = plug.VariationPlugs.map(x => x.nuvemShopOrderId)
      return orders.filter((item, i) => orders.indexOf(item) === i);
    }

    return "";
  }

  function stageSituation() {
    if (
      plug && plug.UserPlugs && plug.UserPlugs.length > 0 &&
      plug.UserPlugs.find(userPlug => userPlug.User.idStage === plug.Stage.idStage)
    ) {
      return "Em Processo"
    }

    return "Pendente";
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        <Col>
          <Row className="plugInfoRow">
            <Col>
              <img
                src={plug.urlImageProduct}
                alt="Imagem do produto final da ficha"
              />
            </Col>
            <Col>
              <Row>
                <span className="mt-3 home-title-adm">{plug.name}</span>
              </Row>
              <Row>
                <Label className="text-muted">
                  <span className="boldGp text-body mr-1">
                    <strong>{plug && plug.Stage && plug.Stage.name}:</strong>
                  </span>
                  {stageSituation()}
                  <i className={`fas fa-exclamation-circle ml-1`}></i>
                </Label>
              </Row>
              <Row>
                <span>
                  <strong>Pedidos:</strong> {OrderNumbers()}
                </span>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <PlugOrderTable plug={plug} setLoading={setLoading} />
          </Row>
        </Col>
      </>
    </Page>
  );
}
