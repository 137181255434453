import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";

import "./styles.scss";
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  Table,
} from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import CustomPagination from "components/CustomPagination";
import maskCpfCnpj from "common/maskCpfCnpj";
import maskPhone from "common/maskPhone";

export default function Customers() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchCustomers();
  }, [page]);

  async function fetchCustomers() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/customer?limit=${limit}&offset=${page - 1}&search=${search}`
      );

      setCustomers(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function deleteCustomer(idCustomer) {
    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir esse cliente?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.delete(`/customer/${idCustomer}`);
          Swal.fire("Pronto!", "O cliente foi excluido com sucesso", "success");
          await fetchCustomers();
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page loading={loading}>
      <>
        <Row className="mb-4">
          <Col md="4">
            <InputGroup>
              <Input
                className="table-search-input"
                placeholder="Buscar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClick={() => fetchCustomers()}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    fetchCustomers();
                  }
                }}
              />
              <InputGroupAddon color="primary" addonType="append">
                <Button
                  className="table-search-button"
                  onClick={() => fetchCustomers()}
                >
                  <i className="fas fa-search" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="8" className="d-flex justify-content-end">
            <Button
              color="success"
              onClick={() => history.push(`/admin/cliente`)}
            >
              Criar novo cliente
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              className="d-none d-md-table sales-customers-table text-center"
              responsive
              striped
            >
              <thead>
                <tr>
                  <th className="TableTh">Nome</th>
                  <th className="TableTh">CPF/CNPJ</th>
                  <th className="TableTh w-15">Últ. Atendimento</th>
                  <th className="TableTh">Email</th>
                  <th className="TableTh">Telefone</th>
                  <th className="TableTh">Ações</th>
                </tr>
              </thead>
              <tbody>
                {customers && customers.length === 0 ? (
                  <tr>
                    <td colSpan="6">Não foi encontrado nenhum registro...</td>
                  </tr>
                ) : (
                  customers.map((customer) => (
                    <tr key={customer.idCustomer}>
                      <td className="TableTd">{customer.name}</td>
                      <td className="TableTd">
                        {maskCpfCnpj(customer.cpfCnpj)}
                      </td>
                      <td className="TableTd">
                        {customer.User && customer.User.abbreviation}
                      </td>
                      <td className="TableTd">{customer.email}</td>
                      <td className="TableTd">
                        {maskPhone(customer.cellphone)}
                      </td>
                      <td className="TableTd">
                        <div className="table-action">
                          <div
                            className="table-action-button-sucess"
                            onClick={() =>
                              history.push(
                                `/admin/nova-venda?idCustomer=${customer.idCustomer}`
                              )
                            }
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </div>
                          <div
                            className="table-action-button-edit"
                            onClick={() =>
                              history.push(
                                `/admin/cliente?idCustomer=${customer.idCustomer}`
                              )
                            }
                          >
                            <i className="fas fa-edit" />
                          </div>
                          <div
                            className="table-action-button-delete"
                            onClick={() => deleteCustomer(customer.idCustomer)}
                          >
                            <i className="fas fa-trash-alt" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {total > limit && (
              <div className="d-flex my-3 justify-content-center">
                <CustomPagination
                  total={total}
                  page={page}
                  length={customers.length}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    </Page>
  );
}
