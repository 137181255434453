import React from "react";

import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import "./styles.scss";

import { Row, Col, Card, Button } from "reactstrap";

import api from "config/api";
import errorRequest from "common/errorRequest";

export default function RewardCard({
  premium,
  user,
  setLoading,
  setPremiums,
  setTotalRescuePremiums,
}) {
  const history = useHistory();
  const colorScore =
    user.monthlyPoints > premium.points ? "text-success" : "text-danger";

  async function rescuePremium() {
    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente resgatar esse prêmio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.post(`/premium/${premium.idPremium}/rescue`);
          user.monthlyPoints -= premium.points;
          premium.quantity--;
          if (premium.quantity === 0) {
            setPremiums((premiums) =>
              premiums.filter((x) => x.idPremium !== premium.idPremium)
            );
          }
          setTotalRescuePremiums(
            (totalRescuePremiums) => totalRescuePremiums + 1
          );
          Swal.fire("Pronto!", "O prêmio foi resgatado com sucesso", "success");
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Card className="reward-card">
      <div className="reward-img-container">
        <img
          className="reward-img"
          src={premium.urlPremiumImage}
          alt="Imagem do Prêmio"
        />
      </div>
      <Row>
        <Col className="reward-title">
          <span>{premium.name}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={`mb-1 reward-score-needs text-muted`}>
            <span>Qtd: {premium.quantity}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="reward-description">
          <span>{premium.description}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={`mb-1 reward-score-needs ${colorScore}`}>
            <span>{premium.points} pontos</span>
          </div>
          <div className="reward-button">
            {user.monthlyPoints >= premium.points ? (
              <Button color="primary" onClick={() => rescuePremium()}>
                Resgatar Prêmio
              </Button>
            ) : (
              <Button disabled color="dark">
                Pontuação insuficiente
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
