import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import Swal from "sweetalert2";

import "./styles.scss";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  InputGroup,
  Button,
} from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import formatCurrency from "common/formatCurrency";

const paymentMethodList = {
  Boleto: 1,
  CartaoDeCredito: 2,
};

const listOrderStatus = {
  EstoquePendenteAprovacao: 1,
  AguardandoPagamento: 2,
  Pago: 3,
  Autorizado: 4,
  ProcessandoPagamento: 5,
  AguardandoProcessamento: 6,
  Estornado: 7,
  TransaçãoRecusada: 8,
};

export default function SingleOrder() {
  const { nuvemShopIdOrder } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [haveStock, setHaveStock] = useState(false);

  useEffect(() => {
    fetchOrder();
  }, []);

  async function fetchOrder() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/order/${nuvemShopIdOrder}`);
      setOrder(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function createBoleto(event, idOrder) {
    event.preventDefault();

    setLoading(true);
    try {
      const { data } = await api.get(`/order/${idOrder}`);
      setOrder(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function confirmStock(event) {
    event.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente confirmar o estoque desse pedido?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          await api.post(`/order/${order.idOrder}/confirmStock`, { haveStock });

          Swal.fire(
            "Salvo!",
            "O estoque do pedido foi salvo com sucesso",
            "success"
          );
          history.push("/admin/pedidos");
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        {order && (
          <Row>
            <Col className="col-8">
              <Form>
                {order.Customer && (
                  <FormGroup>
                    <Label className="form-label" for="inputClient">
                      Cliente
                    </Label>
                    <Input
                      className="form-input"
                      id="inputClient"
                      disabled
                      value={order.Customer.name}
                    />
                  </FormGroup>
                )}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="form-label" for="inputValor">
                        Meio de envio:
                      </Label>
                      <Input
                        className="form-input"
                        id="inputValor"
                        disabled
                        value={order.isSedex ? "Sedex" : "PAC"}
                      />
                    </FormGroup>
                  </Col>
                  {order.PaymentMethod && (
                    <Col md={6}>
                      <FormGroup>
                        <Label className="form-label" for="inputPayment">
                          Método de Pagamento
                        </Label>
                        <Input
                          className="form-input"
                          id="inputPayment"
                          disabled
                          value={order.PaymentMethod.name}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label className="form-label" for="inputValor">
                        Valor da Compra
                      </Label>
                      <Input
                        className="form-input"
                        id="inputValor"
                        disabled
                        value={formatCurrency(order.total)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label className="form-label" for="inputValor">
                        Valor do Frete
                      </Label>
                      <Input
                        className="form-input"
                        id="inputValor"
                        disabled
                        value={formatCurrency(order.freight)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label className="form-label" for="inputPayment">
                        Valor do Desconto
                      </Label>
                      <Input
                        className="form-input"
                        id="inputPayment"
                        disabled
                        value={formatCurrency(order.discount)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              {order.idOrderStatus !==
                listOrderStatus.EstoquePendenteAprovacao && (
                <Col className="d-flex justify-content-end col-12 mb-3">
                  <FormGroup className="newsale-adm-check" check>
                    <Label check>
                      <Input
                        type="checkbox"
                        disabled
                        defaultChecked={order.haveStock}
                        value={true}
                      />
                      Produtos sem estoque
                    </Label>
                  </FormGroup>
                </Col>
              )}
              <Col className="col-12">
                <Row>
                  <InputGroup>
                    <textarea
                      id="inputDescricao"
                      className="form-input adm-textarea-singleorder"
                      type="text"
                      maxLength="300"
                      rows="2"
                      value={order.description ? order.description : ""}
                      disabled
                    />
                  </InputGroup>
                </Row>
              </Col>
              <Col className="col-12 mt-3 mb-3">
                <Row>
                  <div className="newsale-adm-preview">
                    {order.OrderImages &&
                      order.OrderImages.length > 0 &&
                      order.OrderImages.map((orderImage) => (
                        <div
                          key={"image-" + orderImage.idOrderImage}
                          className="newsale-adm-preview-img"
                        >
                          <img
                            src={orderImage.urlImage}
                            alt="Preview de imagem de referencia para personalização"
                          />
                        </div>
                      ))}
                  </div>
                </Row>
              </Col>
              <Table
                className="d-none d-md-table text-center cart-adm-table"
                responsive
                striped
              >
                <thead>
                  <tr>
                    <th className="TableTh">Imagem</th>
                    <th className="TableTh text-left">Produto</th>
                    <th className="TableTh">Variação</th>
                    <th className="TableTh">Preço</th>
                    <th className="TableTh">Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  {order.OrderItems &&
                    order.OrderItems.length > 0 &&
                    order.OrderItems.map((orderItem) => (
                      <tr key={orderItem.idOrderItem}>
                        <td className="TableTd">
                          <div className="singleorder-table-imgwrapper">
                            <img
                              className="singleorder-table-img"
                              src={orderItem.urlProduct}
                              alt="Imagem do produto"
                            />
                          </div>
                        </td>
                        <td className="TableTd text-left">{orderItem.name}</td>
                        <td className="TableTd">{orderItem.variant}</td>
                        <td className="TableTd">{orderItem.quantity}</td>
                        <td className="TableTd">
                          {formatCurrency(orderItem.price)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
            <Col className="col-4 d-flex flex-column ">
              <span className="mb-3">
                Número do pedido: <strong>{order.number}</strong>
              </span>
              {order.idOrderStatus ===
                listOrderStatus.EstoquePendenteAprovacao && (
                <FormGroup className="mb-3 singleorder-adm-check" check>
                  <Label check>
                    <Input
                      type="checkbox"
                      defaultChecked={order.haveStock}
                      value={true}
                      onChange={() => setHaveStock(!haveStock)}
                    />
                    Produtos sem estoque
                  </Label>
                </FormGroup>
              )}
              {order.OrderStatus && (
                <span className="mb-3">
                  Status:{" "}
                  <strong className={`singleorder-status-green`}>
                    {order.OrderStatus.name}
                  </strong>
                </span>
              )}
              {order &&
                order.idPaymentMethod === paymentMethodList.CartaoDeCredito &&
                !order.transactionCode && (
                  <span className="mb-3 d-flex">
                    Link para finalização:
                    <span
                      className="ml-2 singleorder-button button-green"
                      onClick={() =>
                        history.push(`/admin/checkout/${order.idOrder}`)
                      }
                    >
                      <i className="fas fa-barcode" />
                    </span>
                  </span>
                )}
              {order &&
                order.idPaymentMethod === paymentMethodList.Boleto &&
                !order.transactionCode && (
                  <span className="mb-3 d-flex">
                    Link para finalização:
                    <span
                      className="ml-2 singleorder-button"
                      onClick={(e) => createBoleto(e, order.idOrder)}
                    >
                      <i className="fas fa-barcode" />
                    </span>
                  </span>
                )}
              {order &&
                order.idPaymentMethod === paymentMethodList.Boleto &&
                order.transactionCode &&
                order.boletoUrl &&
                order.idOrderStatus !==
                  listOrderStatus.EstoquePendenteAprovacao && (
                  <span className="mb-3 d-flex">
                    Boleto:
                    <a
                      href={order.boletoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-barcode" />
                    </a>
                  </span>
                )}
              <Button color="success">Marcar pagamento recebido</Button>
              {order.idOrderStatus ===
                listOrderStatus.EstoquePendenteAprovacao && (
                <Button color="success" onClick={(e) => confirmStock(e)}>
                  Confirmar pedido
                </Button>
              )}
            </Col>
          </Row>
        )}
      </>
    </Page>
  );
}
