import React from "react";

import Swal from "sweetalert2";

import api from "config/api";
import errorRequest from "common/errorRequest";
import maskPhone from "common/maskPhone";

export default function GridRowUser({ user, history, setLoading, setUsers }) {
  async function deleteUser() {
    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir esse usuário?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.delete(`/admin/user/${user.idUser}`);
          Swal.fire("Pronto!", "O usuário foi deletado com sucesso", "success");
          setUsers((users) => users.filter((x) => x.idUser !== user.idUser));
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  function premiumNotUsed(premiumUsers) {
    if (!premiumUsers || premiumUsers.length === 0) return 0;

    return premiumUsers.filter((x) => !x.dateOfUse).length;
  }

  return (
    <tr>
      <td className="TableTd table-adm-td">
        <div className="table-adm-imgwrapper">
          <img
            className="table-adm-img"
            src={user.urlUserImage}
            alt="Imagem do produto"
          />
        </div>
      </td>
      <td className="TableTd">{user.name}</td>
      <td className="TableTd">{user.email}</td>
      <td className="TableTd">{maskPhone(user.cellphone)}</td>
      <td className="TableTd">{user.isAdmin ? "Sim" : "Não"}</td>
      <td className="TableTd">
        {premiumNotUsed(user.PremiumUsers) > 0 ? (
          <span
            className="adm-user-redeem"
            onClick={() =>
              history.push(`/admin/premios-em-espera/${user.idUser}`)
            }
          >
            <i className="hasAwards fas fa-trophy" />{" "}
            {premiumNotUsed(user.PremiumUsers)}
            <br />
            em espera
          </span>
        ) : (
          <span>Sem prêmios</span>
        )}
      </td>
      <td className="TableTd">
        <div className="table-adm-action">
          <div
            className="table-action-button-edit"
            onClick={() => history.push(`/admin/usuario?idUser=${user.idUser}`)}
          >
            <i className="fas fa-edit" />
          </div>
          <div
            className="table-action-button-delete"
            onClick={(e) => deleteUser(e, user.idUser)}
          >
            <i className="fas fa-trash-alt"></i>
          </div>
        </div>
      </td>
    </tr>
  );
}
