import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";

import "./styles.scss";
import { Col, Table, Button } from "reactstrap";

import PlugOrderRow from "./PlugOrderRow/index"

import api from "config/api";
import errorRequest from "common/errorRequest";

export default function ({ plug, setLoading }) {
  const history = useHistory();
  const [variationPlugs, setVariationPlugs] = useState([]);

  async function sendForSale(event) {
    event.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          if (variationPlugs.length > 0)
            await api.put(`/admin/plugSystem/VariationPlug`, { 
              variationPlugs: variationPlugs.filter(x => x.quantitySendForSale)
            });

          Swal.fire("Salvo!", "Items baixados com sucesso", "success");
          history.push("/admin/fichas");
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <>
      <Col>
        <Table
          className="d-none d-md-table mt-3 text-center newsale-products-table"
          responsive
        >
          <thead>
            <tr>
              <th className="TableTh">Pedido</th>
              <th className="TableTh">Variação</th>
              <th className="TableTh">Qtde Produzida</th>
              <th className="TableTh">Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              plug &&
                plug.VariationPlugs &&
                plug.VariationPlugs.length > 0 ?
                plug.VariationPlugs.map(variationPlug =>
                  <PlugOrderRow
                    key={variationPlug.idVariationPlug}
                    variationPlug={variationPlug}
                    setVariationPlugs={setVariationPlugs}
                  />
                ) :
                <tr>
                  <td colSpan="5">Não foi encontrado nenhum registro...</td>
                </tr>
            }
          </tbody>
        </Table>
        <Button
          className="float-right mt-3"
          color="primary"
          onClick={(e) => sendForSale(e)}
        >
          Salvar
          </Button>
      </Col>
    </>
  );
}
