import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

export default function DownloadExcel({ filename, element, data }){
    return (
        <ExcelFile filename={filename} element={element}>
            <ExcelSheet data={data} name="Comissoes">
                <ExcelColumn label="Vendedor" value="name"/>
                <ExcelColumn label="Comissão" numFmt="0.00" value="commissionReceived"/>
                <ExcelColumn label="Meta para esse Mês" value="stipulatedGoal"/>
                <ExcelColumn label="Apurado" value="hitGoal"/>
                <ExcelColumn label="Excedente" value={(col) => col.surplus < 0 ? "" : col.surplus}/>
            </ExcelSheet>
        </ExcelFile>
    );
};