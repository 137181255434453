import React, { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import "./styles.scss";
import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  Table,
} from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import CustomPagination from "components/CustomPagination";
import maskCpfCnpj from "common/maskCpfCnpj";
import maskPhone from "common/maskPhone";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ClientTable() {
  const history = useHistory();
  const query = useQuery();
  const idFormOrigin = query.get("idFormOrigin");
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [pending, setPending] = useState(true);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 12;

  useEffect(() => {
    fetchCustomers();
  }, [pending]);

  useEffect(() => {
    fetchCustomers();
  }, [page]);

  async function fetchCustomers() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/customerForm?limit=${limit}&offset=${
          page - 1
        }&idFormOrigin=${idFormOrigin}&search=${search}&pending=${pending}`
      );

      setCustomers(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function attendCustomer(customer) {
    setLoading(true);
    try {
      await api.post(`/customerForm/${customer.idCustomerForm}/attend`);
      customer.pending = false;
      window.open(
        `https://api.whatsapp.com/send?phone=55${customer.cellphone}`
      );
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function returnPending(customer) {
    setLoading(true);
    try {
      await api.post(`/customerForm/${customer.idCustomerForm}/pending`);
      customer.pending = true;
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        <Row>
          <Form className="col-12">
            <Row>
              <Col md="4">
                <InputGroup>
                  <Input
                    className="table-search-input"
                    placeholder="Buscar..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onClick={() => fetchCustomers()}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        fetchCustomers();
                      }
                    }}
                  />
                  <InputGroupAddon color="primary" addonType="append">
                    <Button
                      className="table-search-button"
                      onClick={() => fetchCustomers()}
                    >
                      <i className="fas fa-search" />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Input
                    type="select"
                    onChange={(e) => setPending(e.target.value)}
                    value={pending}
                  >
                    <option value="">Todos os resultados</option>
                    <option value="true">Aguardando contato</option>
                    <option value="false">Concluído</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <Col>
            <Table className="d-none d-md-table text-center" responsive striped>
              <thead>
                <tr>
                  <th className="TableTh">Nome</th>
                  <th className="TableTh">CPF/CNPJ</th>
                  <th className="TableTh">Email</th>
                  <th className="TableTh">Telefone</th>
                  <th className="TableTh">Status</th>
                  <th className="TableTh">Ações</th>
                </tr>
              </thead>
              <tbody>
                {customers && customers.length > 0 ? (
                  customers.map((customer) => (
                    <tr key={customer.idCustomerForm}>
                      <td className="TableTd">{customer.name}</td>
                      <td className="TableTd">
                        {maskCpfCnpj(customer.cpfCnpj)}
                      </td>
                      <td className="TableTd">{customer.email}</td>
                      <td className="TableTd">
                        {maskPhone(customer.cellphone)}
                      </td>
                      <td className="TableTd">
                        {customer.pending ? "Pendente" : "Atendido"}
                      </td>
                      <td className="TableTd">
                        <div className="table-action">
                          {!customer.pending && (
                            <div
                              className="table-action-button-sucess"
                              onClick={() =>
                                history.push(
                                  `/admin/nova-venda?idCustomerForm=${customer.idCustomerForm}`
                                )
                              }
                            >
                              <i className="fas fa-shopping-cart" />
                            </div>
                          )}
                          {customer.pending ? (
                            <div
                              className="table-action-button-sucess"
                              onClick={() => attendCustomer(customer)}
                            >
                              <i className="fab fa-whatsapp" />
                            </div>
                          ) : (
                            <div
                              className="table-action-button-sucess"
                              onClick={() => returnPending(customer)}
                            >
                              <i className="fas fa-undo" />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">Não foi encontrado nenhum registro...</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {total > limit && (
              <div className="d-flex my-3 justify-content-center">
                <CustomPagination
                  total={total}
                  page={page}
                  length={customers.length}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    </Page>
  );
}
