import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";

import { Row, Col } from "reactstrap";

import api from 'config/api';
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import CustomPagination from 'components/CustomPagination';

export default function Site() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formOrigins, setFormOrigins] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 12;

  useEffect(() => {
    fetchFormOrigins();
  }, [page]);

  async function fetchFormOrigins() {
    setLoading(true);
    try {
      const { data } = await api.get(`/formOrigin?limit=${limit}&offset=${page - 1}`);

      setFormOrigins(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <Page loading={loading}>
      <Row>
        <Col className="site-option-col">
          {
            formOrigins &&
            formOrigins.length > 0 &&
            formOrigins.map(({ idFormOrigin, classIcon, name }) =>
              <div
                key={idFormOrigin}
                className="site-option"
                onClick={() => history.push(`/admin/site-form?idFormOrigin=${idFormOrigin}`)}
              >
                <i className={classIcon}></i>
                <p>{name}</p>
              </div>
            )
          }
        </Col>
        {total > limit && (
          <div className="d-flex my-3 justify-content-center">
            <CustomPagination
              total={total}
              page={page}
              length={formOrigins.length}
              limit={limit}
              setPage={setPage}
            />
          </div>
        )}
      </Row>
    </Page>
  );
}
