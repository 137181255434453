import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import ReactSpeedometer from "react-d3-speedometer";

import { CanvasJSChart } from "canvasjs-react-charts";

import "./styles.scss";

import { Row, Col } from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";

import LastSale from "./LastSale";
import SellerRanking from "./SellerRanking";

export default function SalesPanel() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [orderedUsers, setOrderedUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    setLoading(true);
    try {
      const { data } = await api.get(`/user/saleStatistic`);
      setUsers(data.data);
      orderUsers(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function orderUsers(users) {
    let sortUsers = [...users];
    sortUsers.sort(function (a, b) {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
    setOrderedUsers(sortUsers);
  }

  const options = {
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Vendas",
    },
    exportEnabled: true,
    exportFileName: "Vendas-7M-Whatsapp",
    height: 256,
    dataPointMaxWidth: 400,
    backgroundColor: "#fff0",
    axisX: {
      title: "Vendedores",
      reversed: true,
    },
    data: [
      {
        type: "bar",
        dataPoints:
          orderedUsers.length > 0
            ? orderedUsers.map((user) => {
                return { y: user.hitGoal, label: user.name };
              })
            : [],
      },
    ],
  };

  return (
    <Page border="salespanel-border" loading={loading}>
      <></>
      <Row>
        <Col className="col-8">
          <CanvasJSChart options={options} />
          <div className="mt-3">
            {users &&
              users.Orders &&
              users.Orders.length > 0 &&
              users.Orders.map((order) => (
                <LastSale key={order.idOrder} order={order} name={users.name} />
              ))}
          </div>
        </Col>
        <Col className="col-4 salespanel-ranking">
          {users && users.length > 0 && (
            <Row className="justify-content-center mt-3">
              <ReactSpeedometer
                maxValue={users
                  .map((x) => parseInt(x.monthlyGoal) * 2)
                  .reduce((total, num) => total + num)}
                value={users
                  .map((x) => parseInt(x.hitGoal))
                  .reduce((total, num) => total + num)}
                segments={7}
                width={260}
                height={160}
                maxSegmentLabels={2}
                needleColor="#6D78AD"
                segmentColors={[
                  "#DF7970",
                  "#E3CB64",
                  "#51CDA0",
                  "#51CDA0",
                  "#51CDA0",
                  "#6D78AD",
                  "#6D78AD",
                ]}
                needleTransitionDuration={3333}
                needleTransition="easeElastic"
              />
            </Row>
          )}
          <Row className="justify-content-center">
            <SellerRanking users={users} />
          </Row>
        </Col>
      </Row>
    </Page>
  );
}
