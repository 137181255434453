import React, { useState, useEffect } from "react";

export default function ({ variationPlug, setVariationPlugs }) {
  const [quantitySendForSale, setQuantitySendForSale] = useState(0);

  useEffect(() => {
    setVariationPlugs((variationPlugs) =>
      insertVariationPlugEdited(variationPlugs, variationPlug.idVariationPlug)
    );
  }, [quantitySendForSale]);

  function increaseQuantity(event) {
    event.preventDefault();
    setQuantitySendForSale((quantitySendForSale) => quantitySendForSale + 1);
  }

  function decreaseQuantity(event) {
    event.preventDefault();
    setQuantitySendForSale((quantitySendForSale) => quantitySendForSale - 1);
  }

  function insertVariationPlugEdited(variationPlugs, idVariationPlug) {
    const variation = variationPlugs.find(
      (variationPlug) => variationPlug.idVariationPlug === idVariationPlug
    );
    if (variation) {
      variation.quantitySendForSale = quantitySendForSale;
      return [
        ...variationPlugs.filter((x) => x.idVariationPlug !== idVariationPlug),
        variation,
      ];
    } else {
      return [...variationPlugs, { idVariationPlug, quantitySendForSale }];
    }
  }

  return (
    <tr>
      <td className="TableTd">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://demoagenciaevacommerce.lojavirtualnuvem.com.br/admin/orders/${variationPlug.nuvemShopOrderId}`}
        >
          {variationPlug.nuvemShopOrderId}{" "}
          <i class="fas fa-external-link-alt" />
        </a>
      </td>
      <td className="TableTd w-15">{variationPlug.variationValue}</td>
      <td className="TableTd w-25">{`${quantitySendForSale}/${variationPlug.quantity}`}</td>
      <td className="TableTd">
        <div className="table-action">
          {quantitySendForSale === variationPlug.quantity ? (
            <div className="table-action-button-disabled">
              <i className="fas fa-plus-circle" />
            </div>
          ) : (
            <div
              className="table-action-button-edit"
              onClick={(e) => increaseQuantity(e)}
            >
              <i className="fas fa-plus-circle" />
            </div>
          )}
          {quantitySendForSale === 0 ? (
            <div className="table-action-button-disabled">
              <i className="fas fa-minus-circle" />
            </div>
          ) : (
            <div
              className="table-action-button-delete"
              onClick={(e) => decreaseQuantity(e)}
            >
              <i className="fas fa-minus-circle" />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}
