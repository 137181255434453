import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import Swal from "sweetalert2";
import NumberFormat from "react-number-format";

import "./styles.scss";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Button,
} from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import PaymentCard from "react-payment-card-component";
import formatCurrency from "common/formatCurrency";

export default function Checkout() {
  const { idOrder } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [holderName, setHolderName] = useState("");
  const [expiration, setExpiration] = useState("");
  const [flipped, setFlipped] = useState(false);
  const [order, setOrder] = useState({});

  useEffect(() => {
    fetchOrder();
  }, []);

  async function fetchOrder() {
    setLoading(true);
    try {
      const { data } = await api.get(`/order/${idOrder}`);
      setOrder(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function savePayment(event) {
    event.preventDefault();
    if (
      !number ||
      !cvv ||
      !holderName ||
      !expiration
    ) {
      Swal.fire(
        "Atenção",
        "Preencha todos os campos e tente novamente!",
        "warning"
      );
      return;
    }

    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        const data = {
          creditCard: {
            card_number: number.replace(/\D/g, ""),
            card_cvv: cvv,
            card_expiration_date: expiration.replace(/\D/g, ""),
            card_holder_name: holderName,
          },
        };

        try {
          await api.post(`/order/${idOrder}/creditCardPayment`, data);

          Swal.fire("Salvo!", "O pagamento foi feito com sucesso", "success");
          history.push(`/admin/pedido/${order.nuvemShopIdOrder}`);
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        {
          order &&
          <Row>
            <Col className="col-7">
              <Col className="checkout-adm-info-col col-12">
                <Row>
                  <span className="col-6">
                    <strong>Pedido nº {order.number}</strong>
                  </span>
                  <span className="col-6 text-right">
                    <strong>Valor total: </strong>{formatCurrency(order.total)}
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>Cliente: </strong>{order.Customer && order.Customer.name}
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>{`Vendedor(a)`}: </strong>{order.User && order.User.name}
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>CEP: </strong>{order.Customer && order.Customer.cep}
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>Endereço: </strong>
                    {
                      order.Customer &&
                      order.Customer.street + " " + order.Customer.number + ", " + order.Customer.neighborhood
                    }
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>Cidade: </strong>
                    {
                      order.Customer &&
                      (order.Customer.city + "/" + order.Customer.province)
                    }
                  </span>
                </Row>
              </Col>
              <Col className="checkout-adm-info-col col-12">
                <Row>
                  <span className="col">
                    <strong>{`Subtotal do(s) item(ns): `} </strong>{formatCurrency(order.subtotal)}
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>Frete: </strong>{formatCurrency(order.freight)}
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>Meio de envio: </strong>{order.isSedex ? "Sedex" : "PAC"}
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>Desconto: </strong>{formatCurrency(order.discount)}
                  </span>
                </Row>
                <Row>
                  <span className="col">
                    <strong>Produtos</strong>
                  </span>
                </Row>
                <Row>
                  <Col>
                    <Table
                      className="d-none d-md-table text-center newsale-products-table"
                      responsive
                    >
                      <thead>
                        <tr>
                          <th className="TableTh">Foto</th>
                          <th className="TableTh text-left">Produto</th>
                          <th className="TableTh text-left">Variação</th>
                          <th className="TableTh">Preço</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.OrderItems &&
                          order.OrderItems.length > 0 &&
                          order.OrderItems.map((orderItem) => (
                            <tr key={orderItem.idOrderItem}>
                              <td className="TableTd">
                                <div className="singleorder-table-imgwrapper">
                                  <img
                                    className="singleorder-table-img"
                                    src={orderItem.urlProduct}
                                    alt="Imagem do produto"
                                  />
                                </div>
                              </td>
                              <td className="TableTd text-left w-75">{orderItem.name}</td>
                              <td className="TableTd text-left w-75">{orderItem.variant}</td>
                              <td className="TableTd">{formatCurrency(orderItem.price)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col className="col-5">
              <div>
                <PaymentCard
                  bank=""
                  brand=""
                  number={number}
                  cvv={cvv}
                  holderName={holderName}
                  expiration={expiration}
                  flipped={flipped}
                />
              </div>
              <FormGroup className="mt-3">
                <Label className="form-label" for="inputNum">
                  Número do cartão
            </Label>
                <InputGroup className="mb-1">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <NumberFormat
                    className="form-input"
                    type="text"
                    id="inputNum"
                    placeholder="4111 1111 1111 1111"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    customInput={Input}
                    format="#### #### #### ####"
                  />
                </InputGroup>
                <Label className="form-label" for="inputName">
                  Nome no cartão
            </Label>
                <InputGroup className="mb-1">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-input"
                    type="text"
                    id="inputName"
                    placeholder="Nome"
                    maxLength="28"
                    value={holderName}
                    onChange={(e) => setHolderName(e.target.value)}
                  />
                </InputGroup>
                <Row form>
                  <Col className="col-8">
                    <FormGroup className="mb-1">
                      <Label className="form-label" for="inputDate">
                        Data de vencimento
                  </Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="far fa-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <NumberFormat
                          id="inputDate"
                          className="form-input"
                          type="text"
                          placeholder="Data"
                          value={expiration}
                          onChange={(e) => setExpiration(e.target.value)}
                          customInput={Input}
                          format="##/##"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col className="col-4">
                    <FormGroup className="mb-1">
                      <Label className="form-label" for="inputCvv">
                        CVV
                  </Label>
                      <InputGroup>
                        <NumberFormat
                          id="inputCvv"
                          className="form-input"
                          type="tel"
                          placeholder="cvv"
                          value={cvv}
                          onChange={(e) => setCvv(e.target.value)}
                          onFocus={() => setFlipped(!flipped)}
                          onBlur={() => setFlipped(!flipped)}
                          customInput={Input}
                          format="###"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Button
                    className="float-right mt-3"
                    color="primary"
                    onClick={(e) => savePayment(e)}
                  >
                    Efetuar pagamento
                  </Button>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        }
      </>
    </Page>
  );
}
