import React, { useState, useEffect } from "react";

import { FormGroup, Input } from "reactstrap";

import formatCurrency from "common/formatCurrency";

export default function TableLineProduct({
  product,
  lastProductElementRef,
  setProducts,
}) {
  const variation = product.variants[0];
  const [nuvemShopIdVariant, setNuvemShopIdVariant] = useState(variation.id);
  const [price, setPrice] = useState(priceVariation(variation));
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (nuvemShopIdVariant) {
      const variantProduct = product.variants.find((x) => x.id.toString() === nuvemShopIdVariant.toString());
      if (variantProduct)
        setPrice(priceVariation(variantProduct));
    }
  }, [nuvemShopIdVariant]);

  function changeQuantity(value) {
    const variant = product.variants.find((x) => x.id.toString() === nuvemShopIdVariant.toString());
    if (variant.stock_management && value > variant.stock)
      setQuantity(variant.stock);
    else if (value < 1) setQuantity(1);
    else setQuantity(value);
  }

  function priceVariation(variation) {
    return variation.promotional_price ? variation.promotional_price : variation.price;
  }

  function addProduct() {
    const newProduct = {
      quantity,
      price,
      nuvemShopIdVariant,
      name: product.name.pt,
      urlProduct: product.images[0].src,
      variants: product.variants.filter(x => !x.stock_management || x.stock > 0),
    };
    setProducts((products) => [...products, newProduct]);
  }

  return (
    <tr ref={lastProductElementRef}>
      <td className="TableTd">
        <div className="singleorder-table-imgwrapper">
          <img
            className="singleorder-table-img"
            src={product.images[0].src}
            alt="Imagem do produto"
          />
        </div>
      </td>
      <td className="TableTd text-left">{product.name.pt}</td>
      <td className="TableTd">
        <FormGroup className="mb-0">
          <Input
            className="pr-2"
            type="select"
            value={nuvemShopIdVariant}
            onChange={(e) => setNuvemShopIdVariant(e.target.value)}
          >
            {product.variants.filter(x => !x.stock_management || x.stock > 0).map((variant) => (
              <option key={"variant-" + variant.id} value={variant.id}>
                {variant.values.length
                  ? variant.values.map((x) => x.pt).join(" / ")
                  : ""}
              </option>
            ))}
          </Input>
        </FormGroup>
      </td>
      <td className="TableTd">{formatCurrency(price)}</td>
      <td className="TableTd">
        <Input
          type="number"
          value={quantity}
          onChange={(e) => changeQuantity(e.target.value)}
        />
      </td>
      <td className="TableTd">
        <div className="table-action">
          <div
            className="table-action-button-edit"
            onClick={() => window.open(product.canonical_url)}
          >
            <i className="fas fa-eye" />
          </div>
          <div
            className="table-action-button-sucess"
            onClick={() => addProduct()}
          >
            <i className="fas fa-arrow-right" />
          </div>
        </div>
      </td>
    </tr>
  );
}
