import React, { useState } from "react";

import moment from "moment";
import RewardModal from "./RewardModal";

import "./styles.scss";

import { Row, Col, Card, Button } from "reactstrap";

export default function RedeemCard({ premiumUser, setLoading }) {
  const [modalReward, setModalReward] = useState(false);
  return (
    <Card className="reward-card">
      <RewardModal
        toggleModal={() => setModalReward(!modalReward)}
        isOpen={modalReward}
        premiumUser={premiumUser}
        setLoading={setLoading}
      />
      <div className="reward-img-container">
        <img
          className="reward-img"
          src={premiumUser.Premium.urlPremiumImage}
          alt="Imagem do Prêmio"
        />
      </div>
      <Row>
        <Col className="reward-title">
          <span>{premiumUser.Premium.name}</span>
        </Col>
      </Row>
      <Row>
        <Col className="reward-description">
          <span>{premiumUser.Premium.description}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-1 redeem-date">
            {premiumUser.dateOfUse ? (
              <>
                <div className={`mb-1 reward-score-needs text-muted`}>
                  <span>
                    {" "}
                    Resgatado em{" "}
                    {moment(premiumUser.dateOfUse).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div>
                  <Button
                    onClick={() => setModalReward(true)}
                    color="primary w-100 mb-3"
                  >
                    Alterar agendamento
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={`mb-1 reward-score-needs text-muted`}>
                  <span>Aguardando resgate</span>
                </div>
                <div>
                  <Button
                    onClick={() => setModalReward(true)}
                    color="success w-100 mb-3"
                  >
                    Agendar prêmio
                  </Button>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
