import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "components/DatePicker";

import { useHistory, useLocation } from "react-router-dom";

import "./styles.scss";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import states from "common/statesOfBrazil";
import maskCpfCnpj from "common/maskCpfCnpj";
import maskPhone from "common/maskPhone";
import Page from "components/Page";
import api from "config/api";
import errorRequest from "common/errorRequest";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SingleCustomer() {
  const history = useHistory();
  const query = useQuery();
  const idCustomer = query.get("idCustomer");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("AC");
  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [birthday, setBirthday] = useState("");

  async function buscaCep() {
    setLoading(true);
    await axios
      .get(
        `https://viacep.com.br/ws/${cep
          .replace(".", "")
          .replace("-", "")}/json/`
      )
      .then((res) => {
        setNeighborhood(res.data.bairro);
        setCity(res.data.localidade);
        setProvince(res.data.uf);
        setStreet(res.data.logradouro);
        return;
      })
      .catch(() => {
        Swal.fire("Erro", "Verifique o cep e tente novamente!", "error");
      });
    setLoading(false);
  }

  useEffect(() => {
    if (idCustomer) fetchCustomer();
  }, []);

  async function fetchCustomer() {
    setLoading(true);
    try {
      const { data } = await api.get(`/customer/${idCustomer}`);

      setName(data.data.name);
      setEmail(data.data.email);
      setCpfCnpj(data.data.cpfCnpj);
      setNeighborhood(data.data.neighborhood);
      setCity(data.data.city);
      setProvince(data.data.province);
      setStreet(data.data.street);
      setCep(data.data.cep);
      setNumber(data.data.number);
      setComplement(data.data.complement ? data.data.complement : "");
      setCellphone(data.data.cellphone);
      setBirthday(new Date(data.data.birthday));
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function saveCustomer(event) {
    event.preventDefault();
    if (checkBirthday(birthday) > 10) {
      if (
        !name ||
        !email ||
        !cpfCnpj ||
        !neighborhood ||
        !city ||
        !province ||
        !street ||
        !cep ||
        !cellphone ||
        !number ||
        !birthday
      ) {
        Swal.fire(
          "Atenção",
          "Preencha todos os campos e tente novamente!",
          "warning"
        );
        return;
      }
      Swal.fire({
        title: "Atenção",
        text: "Deseja Realmente salvar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
      }).then(async (result) => {
        if (result.value) {
          setLoading(true);

          const data = {
            name,
            email,
            cpfCnpj: cpfCnpj.replace(/\D/g, ""),
            neighborhood,
            city,
            province,
            street,
            cep: cep.replace(/\D/g, ""),
            complement,
            cellphone: cellphone.replace(/\D/g, ""),
            number,
            birthday,
          };

          try {
            if (idCustomer) await api.put(`/customer/${idCustomer}`, data);
            else await api.post(`/customer`, data);

            Swal.fire("Salvo!", "O cliente foi salvo com sucesso", "success");
            history.push("/admin/clientes");
          } catch (error) {
            errorRequest(history, error);
          }
          setLoading(false);
        }
      });
    } else {
      Swal.fire(
        "Erro",
        "Verifique a idade do cliente e tente novamente",
        "error"
      );
      return;
    }
  }

  function checkBirthday(nascimento) {
    let idade = new Date(nascimento);
    let hoje = new Date();
    let anoAtual = hoje.getFullYear();
    let ano = idade.getFullYear();
    return anoAtual - ano;
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        <Form>
          <Row>
            <Col className="col-6">
              <FormGroup className="mb-1">
                <Label className="form-label" for="inputName">
                  Nome
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-input"
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputDoc">
                      CPF/CNPJ
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-id-card" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <NumberFormat
                        className="form-input"
                        type="text"
                        id="inputDoc"
                        placeholder="CPF/CNPJ"
                        format={maskCpfCnpj}
                        customInput={Input}
                        value={cpfCnpj}
                        onChange={(e) => setCpfCnpj(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <Label className="form-label" for="inputTel">
                    Telefone
                  </Label>
                  <InputGroup className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumberFormat
                      className="form-input"
                      type="text"
                      id="inputTel"
                      placeholder="Telefone"
                      format={maskPhone}
                      customInput={Input}
                      value={cellphone}
                      onChange={(e) => setCellphone(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <FormGroup className="mb-1">
                <Label className="form-label">Data de nascimento</Label>
                <DatePicker
                  value={birthday}
                  onChange={(e) => setBirthday(e._d)}
                />
              </FormGroup>
              <Row form>
                <Col>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputEmail">
                      Email
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputZipcode">
                      CEP
                    </Label>
                    <InputGroup className="mb-1">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-location-arrow" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <NumberFormat
                        className="form-input"
                        type="text"
                        placeholder="CEP"
                        value={cep}
                        onChange={(e) => setCep(e.target.value)}
                        customInput={Input}
                        format="##.###-###"
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            buscaCep();
                          }
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          className="table-search-button"
                          onClick={() => buscaCep()}
                        >
                          <i className="fas fa-search" />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={8}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputAddress">
                      Rua
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-road" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="text"
                        id="inputAddress"
                        placeholder="Rua"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputNumber">
                      Número
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>₁₂₃</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="number"
                        id="inputNumber"
                        placeholder="Número"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputComplement">
                      Complemento
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        id="inputComplement"
                        placeholder="Complemento"
                        value={complement}
                        onChange={(e) => setComplement(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputDistrict">
                      Bairro
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-map" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="text"
                        id="inputDistrict"
                        placeholder="Bairro"
                        value={neighborhood}
                        onChange={(e) => setNeighborhood(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={9}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputCity">
                      Cidade
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-city" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="text"
                        id="inputCity"
                        placeholder="Cidade"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputState">
                      Estado
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <span role="img" aria-label="icone estado">
                            🇸🇵
                          </span>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="inputState"
                        type="select"
                        value={province}
                        onChange={(e) => setProvince(e.target.value)}
                      >
                        {states.map((state) => (
                          <option key={state.id} value={state.nome}>
                            {state.nome}
                          </option>
                        ))}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Button
            className="float-right mt-3"
            color="primary"
            onClick={(e) => saveCustomer(e)}
          >
            Salvar
          </Button>
        </Form>
      </>
    </Page>
  );
}
