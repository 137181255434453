import React, { useEffect, useState } from "react";
import errorRequest from "common/errorRequest";

import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
} from "reactstrap";

import Header from "components/Header";
import Loading from "components/Loading";
import CustomPagination from "components/CustomPagination";
import api from "config/api";
import PlugCard from "./PlugCard";

export default function Plugs() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [plugs, setPlugs] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const limit = 9;

  useEffect(() => {
    fetchPlugs();
  }, [page]);

  async function fetchPlugs() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/admin/plugSystem/plug?offset=${page - 1}&limit=${limit}`
      );
      setPlugs(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <>
      {loading && <Loading />}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-transparent">
                <h1>Fichas</h1>
              </CardHeader>
              <CardBody className="row">
                {plugs && plugs.length > 0 ? (
                  plugs.map((plug) => (
                    <PlugCard key={plug.idPlug} plug={plug} />
                  ))
                ) : (
                  <Row>
                    <Col>
                      <Label>Nenhum registro encontrado...</Label>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {total > 10 && (
          <div className="d-flex my-3 justify-content-center">
            <CustomPagination
              total={total}
              page={page}
              length={plugs.length}
              limit={limit}
              setPage={setPage}
            />
          </div>
        )}
      </Container>
    </>
  );
}
