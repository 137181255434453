import React, { useState, useRef, useCallback, useEffect } from "react";
import Swal from "sweetalert2";

import {
  Modal,
  Label,
  Input,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";

import api from "config/api";
import TableLineProduct from "./TableLineProduct";

export default function ModalProductNuvemShop({
  isOpen,
  toggle,
  setLoading,
  loading,
  products,
  setProducts,
}) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [productsNuvemShop, setProductsNuvemShop] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const limit = 10;

  useEffect(() => {
    fetchProductsNuvemShop(true);
  }, [page]);

  useEffect(() => {
    if (!search) fetchProductsNuvemShop(false);
  }, [search]);

  const observer = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  async function fetchProductsNuvemShop(infiniteScroll) {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/nuvemShopProduct?limit=${limit}&offset=${page - 1}&search=${search}`
      );
      if (infiniteScroll)
        setProductsNuvemShop((productsNuvemShop) => [
          ...productsNuvemShop,
          ...data.data,
        ]);
      else setProductsNuvemShop(data.data);
      const total = data.pagination.total;
      const totalPages = Math.ceil(total / limit);
      if (totalPages === page) setHasMore(false);
      else setHasMore(true);
    } catch (error) {
      Swal.fire("Erro", "O produto não foi encontrado ou não existe", "error");
    }
    setLoading(false);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Adicionar produtos a venda</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <FormGroup>
                <Label className="newsale-label" for="inputProduct">
                  Inserir Produtos
                </Label>
                <InputGroup className="mb-1">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-box-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="newsale-input"
                    type="text"
                    id="inputProduct"
                    placeholder="Digite o nome do produto aqui..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        fetchProductsNuvemShop(false);
                      }
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      color="primary"
                      className="search-button btn btn-secondary"
                      onClick={() => fetchProductsNuvemShop(false)}
                    >
                      Buscar
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                className="d-none d-md-table text-center cart-adm-table"
                responsive
                striped
              >
                <thead>
                  <tr>
                    <th className="TableTh">Imagem</th>
                    <th className="TableTh text-left">Produto</th>
                    <th className="TableTh">Variação</th>
                    <th className="TableTh">Preço</th>
                    <th className="TableTh">Quantidade</th>
                    <th className="TableTh">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {productsNuvemShop && productsNuvemShop.length > 0 ? (
                    productsNuvemShop.map((product, index) => {
                      if (productsNuvemShop.length === index + 1)
                        return (
                          <TableLineProduct
                            lastProductElementRef={lastProductElementRef}
                            key={product.id}
                            product={product}
                            products={products}
                            setProducts={setProducts}
                          />
                        );
                      else
                        return (
                          <TableLineProduct
                            key={product.id}
                            product={product}
                            products={products}
                            setProducts={setProducts}
                          />
                        );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">Nenhum registro encontrado...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
