import React from "react";
import moment from "moment";
import "./styles.scss";

import { Row, Col } from "reactstrap";

import formatCurrency from "common/formatCurrency";

export default function LastSale({ name, order }) {
  const { number, commissionPercentage, created_at } = order;
  return (
    <Row className="justify-content-center">
      <Col className="lastsale-col col-11">
        <span className="lastsale-date">
          {moment(created_at).format("DD/MM/YYYY - H:mm")}
        </span>
        <span className="lastsale-important">{name}</span> confirmou a venda{" "}
        <span className="lastsale-important">{number}</span> e ganhou{" "}
        <span className="lastsale-important">
          {formatCurrency(commissionPercentage)}
        </span>{" "}
        de comissão
      </Col>
    </Row>
  );
}
