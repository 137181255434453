import React from 'react'

import './styles.scss'

import { Row, Col, Table } from "reactstrap";

export default function SellerRanking({ users }) {

  function classPosition(position) {
    switch (position) {
      case 1:
        return "first-place";
      case 2:
        return "second-place";
      case 3:
        return "third-place";
      default:
        return "";
    }
  }

  return (
    <Row>
      <Col className="col-12">
        <Table className="d-none d-md-table text-center table-seller-ranking">
          <tbody>
            {
              users && users.length > 0 &&
              users.map((user, i) =>
                <tr className="TableTr">
                  <td className={"td-withoutborder seller-ranking-place " + classPosition(i + 1)}>
                    <i className={i + 1 < 4 ? "fas fa-medal" : "fas fa-minus"} />
                    {i + 1}
                  </td>
                  <td className="td-withoutborder td-seller">{user.name}</td>
                  <td className="TableTd td-withoutborder">
                    <div className="table-img">
                      <img src={user.urlUserImage} alt="Avatar do usuário" />
                    </div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}