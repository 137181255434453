import React, { useState, useEffect } from "react";

import { FormGroup, Input } from "reactstrap";

import formatCurrency from "common/formatCurrency";

export default function TableLineProductCart({
  product,
  setProducts,
  totalCart,
}) {

  const [nuvemShopIdVariant, setNuvemShopIdVariant] = useState(
    product.nuvemShopIdVariant
  );
  const [price, setPrice] = useState(product.price);
  const [quantity, setQuantity] = useState(product.quantity);

  useEffect(() => {
    totalCart();
  }, [price, quantity]);

  useEffect(() => {
    if (nuvemShopIdVariant) {
      const variationProduct = product.variants.find((x) => x.id.toString() === nuvemShopIdVariant.toString());
      product.price = priceVariation(variationProduct);
      setPrice(product.price);
    }
  }, [nuvemShopIdVariant]);

  function priceVariation(variation) {
    return variation.promotional_price ? variation.promotional_price : variation.price;
  }

  function changeQuantity(value) {
    const variant = product.variants.find((x) => x.id.toString() === product.nuvemShopIdVariant.toString());
    if (variant.stock_management && value > variant.stock) {
      product.quantity = variant.stock;
      setQuantity(variant.stock);
    } else if (value < 1) {
      setQuantity(1);
    } else {
      product.quantity = value;
      setQuantity(value);
    }
  }

  function deleteProduct() {
    setProducts((products) =>
      products.filter(
        (x) => x.nuvemShopIdVariant !== product.nuvemShopIdVariant
      )
    );
  }

  return (
    <tr>
      <td className="TableTd">
        <div className="singleorder-table-imgwrapper">
          <img
            className="singleorder-table-img"
            src={product.urlProduct}
            alt="Imagem do produto"
          />
        </div>
      </td>
      <td className="TableTd text-left">{product.name}</td>
      <td className="TableTd">
        <FormGroup className="mb-0">
          <Input
            type="select"
            value={nuvemShopIdVariant}
            onChange={(e) => setNuvemShopIdVariant(e.target.value)}
          >
            {product.variants.map((variant) => (
              <option key={"variant-" + variant.id} value={variant.id}>
                {variant.values.length
                  ? variant.values.map((x) => x.pt).join(" / ")
                  : ""}
              </option>
            ))}
          </Input>
        </FormGroup>
      </td>
      <td className="TableTd">{formatCurrency(price)}</td>
      <td className="TableTd">
        <Input
          type="number"
          value={quantity}
          onChange={(e) => changeQuantity(e.target.value)}
        />
      </td>
      <td className="TableTd">
        <div className="table-action">
          <div
            className="table-action-button-delete"
            onClick={() => deleteProduct()}
          >
            <i className="fas fa-trash-alt" />
          </div>
        </div>
      </td>
    </tr>
  );
}
