import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Card, CardHeader, CardBody, Label, Row } from "reactstrap";
import "./styles.scss";

export default function PlugCard({ plug }) {
  const { Stage, UserPlugs, VariationPlugs } = plug;

  const history = useHistory();
  const [stateColor, setStateColor] = useState("text-muted");
  const [stateIcon, setStateIcon] = useState("fa-exclamation-circle");
  const [plugState, setPlugState] = useState("Pendente");

  useEffect(() => {
    if (UserPlugs.length > 0) {
      setPlugState("Em andamento");
      setStateColor("text-success");
    } else if (Stage.idStage === 5) {
      setPlugState("Finalizado");
      setStateColor("text-primary");
      setStateIcon("fa-check-circle");
    }
  }, []);

  function totalVariationPlugSend() {
    return VariationPlugs.map(
      (variationPlug) => variationPlug.quantitySendForSale
    ).reduce((num, total) => num + total);
  }

  return (
    <>
      <Card className={`bg-gradient-white shadow mb-3 cardPlug col-lg-4 mx-1`}>
        <CardHeader className="pb-0">
          <Row className="mt-3 cardPlugRow">
            <Label>{plug.idPlug}</Label>
            <Label className="namePlug">{plug.name}</Label>
          </Row>
          <Label className={`plugStatus ${stateColor}`}>
            <i className={`fas ${stateIcon} mr-1`}></i>
            {plugState}
          </Label>
          <Label
            className="plugEdit text-primary"
            onClick={() => history.push(`/admin/informacao-ficha/${plug.idPlug}`)}
          >
            <i className="far fa-eye"></i>
          </Label>
        </CardHeader>
        <CardBody className="cardBodyPlug">
          <Row>
            <Label className="cardBodyLabel">
              <span className="boldGp">Produtos:</span>{" "}
              {`${totalVariationPlugSend()}/${VariationPlugs.length}`}
            </Label>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
