import React from "react";
import ReactDatetime from "react-datetime";

import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";

import "./styles.scss";

export default function Datepicker({
  value,
  onChange,
  dateFormat = "DD/MM/YYYY",
}) {
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className="ni ni-calendar-grid-58" />
        </InputGroupText>
      </InputGroupAddon>
      <ReactDatetime
        inputProps={{
          placeholder: "Data",
        }}
        timeFormat={false}
        dateFormat={dateFormat}
        onChange={onChange}
        value={value}
        input={true}
      />
    </InputGroup>
  );
}
