import UserProfile from "views/admin/UserProfile";
import Home from "views/admin/Home/index.jsx";
import SalesPanel from "views/admin/SalesPanel";
import NewSale from "views/admin/NewSale/index.jsx";
// Sales
import Customers from "views/admin/Sales/Customers/index.jsx";
import SingleCustomer from "views/admin/Sales/Customers/SingleCustomer/index.jsx";
import Orders from "views/admin/Sales/Orders/index.jsx";
import SingleOrder from "views/admin/Sales/Orders/SingleOrder/index.jsx";

import Checkout from "views/admin/Checkout/index.jsx";
// Site
import Site from "views/admin/Site/index.jsx";
import ClientTable from "views/admin/Site/ClientTable/index.jsx";
// Premios
import Rewards from "views/admin/Rewards/index.jsx";
import Redeem from "views/admin/Rewards/Redeem/index.jsx";
// Configurações ADM
import Settings from "views/admin/Settings/index.jsx"

import Users from "views/admin/Users/index.jsx"
import User from "views/admin/Users/User/index.jsx"

import RewardsGrid from "views/admin/Settings/RewardsGrid/index.jsx"
import RewardsCreation from "views/admin/Settings/RewardsGrid/RewardsCreation/index.jsx"

import Plugs from 'views/admin/Plugs/index.jsx'
import PlugInfo from 'views/admin/Plugs/PlugInfo/index.jsx'

import NotFound from "views/admin/NotFound";

var routes = [
  {
    path: "/user-profile",
    name: "Perfil do usuário",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Página inicial",
    icon: "fas fa-home",
    component: Home,
    layout: "/admin",
  },
  {
    path: "/painel-de-vendas",
    name: "Painel de vendas",
    invisible: true,
    component: SalesPanel,
    layout: "/admin",
  },
  {
    path: "/nova-venda",
    name: "Realizar nova venda",
    invisible: true,
    component: NewSale,
    layout: "/admin",
  },

  // // // // // // // // // // // // //
  // Salles //
  {
    collapse: true,
    name: "Vendas",
    icon: "fas fa-shopping-basket",
    state: "salesCollapse",
    views: [
      {
        path: "/clientes",
        sidebarname: "● Clientes",
        component: Customers,
        layout: "/admin",
      },
      {
        path: "/pedidos",
        sidebarname: "● Pedidos",
        component: Orders,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/clientes",
    name: "Tabela de Clientes",
    invisible: true,
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/cliente",
    name: "Visualização de Cliente",
    invisible: true,
    component: SingleCustomer,
    layout: "/admin",
  },
  {
    path: "/pedidos",
    name: "Tabela de Pedidos",
    invisible: true,
    component: Orders,
    layout: "/admin",
  },
    {
    path: "/pedido/:nuvemShopIdOrder",
    name: "Visualização de Pedido",
    invisible: true,
    component: SingleOrder,
    layout: "/admin",
  },

  {
    path: "/checkout/:idOrder",
    name: "Checkout do pedido",
    invisible: true,
    component: Checkout,
    layout: "/admin",
  },

  // // // // // // // // // // // // //
  // Site //
  {
    path: "/site",
    name: "Visualização de formulários do site",
    sidebarname: "Site",
    icon: "fas fa-laptop",
    component: Site,
    layout: "/admin",
  },
  {
    path: "/site-form",
    invisible: true,
    component: ClientTable,
    layout: "/admin",
  },
  // // // // // // // // // // // // //
  // Premios //
  {
    path: "/premios",
    name: "Resgate de prêmios",
    sidebarname: "Premiação",
    icon: "fas fa-trophy",
    component: Rewards,
    layout: "/admin",
  },
  {
    path: "/premios-em-espera/:idUser",
    name: "Confira seus prêmios",
    invisible: true,
    component: Redeem,
    layout: "/admin",
  },
  // // // // // // // // // // // // //
  // Configurações ADM //
  {
    path: "/usuarios",
    name: "Listagem de usuários",
    sidebarname: "Usuários",
    icon: "fas fa-user",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/usuario",
    name: "Gerenciamento de usuário",
    invisible: true,
    component: User,
    layout: "/admin",
  },
  // PlugSystem ADM //
  {
    path: "/fichas",
    name: "Visualização de fichas finalizadas",
    sidebarname: "Fichas personalizadas",
    icon: "fas fa-ticket-alt",
    component: Plugs,
    layout: "/admin",
  },
  {
    path: "/informacao-ficha/:idPlug",
    name: "Visualização de fichas finalizadas",
    invisible: true,
    component: PlugInfo,
    layout: "/admin",
  },
    // Configs gerais ADM //
  {
    path: "/configuracoes",
    name: "Configurações do sistema e usuários",
    sidebarname: "Configurações",
    icon: "fas fa-cog",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/config-premios",
    name: "Listagem de premiações",
    invisible: true,
    component: RewardsGrid,
    layout: "/admin",
  },
  {
    path: "/config-premio",
    name: "Gerenciamento de premiação",
    invisible: true,
    component: RewardsCreation,
    layout: "/admin",
  },

  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
